import * as React from 'react';
import {useEffect, useState} from 'react';
import {API} from "aws-amplify";
import {getCampaigns, getCustomers} from "../../graphql/queries";
import _ from "lodash";
import CustomSelect from "../common/CustomSelect/CustomSelect";
import Box from '@mui/material/Box';

const ConversationFilter = ({onError, onSelect}) => {
    const [customers, setCustomers] = useState([]);
    const [customer, setCustomer] = useState('');
    const [campaigns, setCampaigns] = useState([]);
    const [laodingCampaigns, setLaodingCampaigns] = useState(false);
    const [laodingCustomers, setLaodingCustomers] = useState(false);

    const initCustomersQuery = async () => {
        console.log('Fetch customers')
        setLaodingCustomers(true)
        try {
            let api_resp = []
            const result = await API.graphql({
                query: getCustomers,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })

            let body = JSON.parse(result.data.getCustomers.body)
            console.log(`Get customers : ${result.data.getCustomers.statusCode} - ${body.message}`)
            if (!_.isUndefined(body.data)) {
                api_resp = body.data
            }
            setCustomers(api_resp)
            setLaodingCustomers(false)

        } catch (error) {
            console.log('querry failed ->', error)
            onError()
        }
    }

    const initCampaignsQuery = async (customer) => {

        if (!customer) return

        console.log('Fetch campaigns')
        setLaodingCampaigns(true)
        try {
            let api_resp = []
            const result = await API.graphql({
                query: getCampaigns,
                variables: {"customer": customer},
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })

            let body = JSON.parse(result.data.getCampaigns.body)
            console.log(`Get Campaigns : ${result.data.getCampaigns.statusCode} - ${body.message}`)
            if (!_.isUndefined(body.data)) {
                api_resp = body.data
            }
            setCampaigns(api_resp)
            setLaodingCampaigns(false)

        } catch (error) {
            console.log('querry failed ->', error)
            onError()
        }
    }

    const onSelectCustomer = (e, customer) => {
        e.preventDefault()
        console.log(customer)
        setCustomer(customer)
        setCampaigns([])
        onSelect(e, '')
    }


    useEffect(() => {
        setCampaigns([])
        if (!customer) return
        console.log('Fetch campaigns queries')
        initCampaignsQuery(customer)
    }, [customer])

    useEffect(() => {
        console.log('Fetch campaigns queries')
        initCustomersQuery()
    }, [])

    return (
        <Box>
            <CustomSelect items={customers} onSelect={onSelectCustomer} name={'Customer'} loading={laodingCustomers}/>
            <CustomSelect items={campaigns} onSelect={onSelect} name={'Campaign'} loading={laodingCampaigns}/>
        </Box>
    )
}

export default ConversationFilter