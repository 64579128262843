import React, {useState} from 'react'
import Box from '@mui/material/Box';
import BasicSnackbar from "../../components/common/BasicSnackbar/BasicSnackbar";
import ConversationMessages from "../../components/ConversationMessages/ConversationMessages";
import ConversationFilter from "../../components/ConversationFilter/ConversationFilter";

const drawerWidth = 240;
const CampaignViewer = () => {
    const [open, setOpen] = useState(false)
    const [campaign, setCampaign] = useState(null)

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const onSelectCampaign = (e, campaign) => {
        e.preventDefault()
        console.log(campaign)
        setCampaign(campaign)
    }

    return (
        <Box
            component="main"
            sx={{flexGrow: 1, p: 3, width: {sm: `calc(100% - ${drawerWidth}px)`}, marginTop: '46px'}}
        >
            <ConversationFilter onError={() => setOpen(true)} campaign={campaign}
                                onSelect={onSelectCampaign}/>

            <ConversationMessages onError={() => setOpen(true)} campaign={campaign}/>

            <BasicSnackbar
                open={open}
                severity="error"
                message="Data couldn't be fetched"
                onClose={handleClose}
            />
        </Box>)
}

export default CampaignViewer