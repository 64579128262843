/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCampaigns = /* GraphQL */ `
  query GetCampaigns($customer: String) {
    getCampaigns(customer: $customer) {
      statusCode
      body
      __typename
    }
  }
`;
export const getConversations = /* GraphQL */ `
  query GetConversations($campaign: String) {
    getConversations(campaign: $campaign) {
      statusCode
      body
      __typename
    }
  }
`;
export const getCustomers = /* GraphQL */ `
  query GetCustomers {
    getCustomers {
      statusCode
      body
      __typename
    }
  }
`;
export const putApproveMessage = /* GraphQL */ `
  query PutApproveMessage($message_id: String, $campaign_id: String) {
    putApproveMessage(message_id: $message_id, campaign_id: $campaign_id) {
      statusCode
      body
      __typename
    }
  }
`;
